exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-en-js": () => import("./../../../src/pages/en.js" /* webpackChunkName: "component---src-pages-en-js" */),
  "component---src-pages-heic-to-jpeg-en-js": () => import("./../../../src/pages/heic-to-jpeg/en.js" /* webpackChunkName: "component---src-pages-heic-to-jpeg-en-js" */),
  "component---src-pages-heic-to-jpeg-index-js": () => import("./../../../src/pages/heic-to-jpeg/index.js" /* webpackChunkName: "component---src-pages-heic-to-jpeg-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-posts-en-js": () => import("./../../../src/pages/posts/en.js" /* webpackChunkName: "component---src-pages-posts-en-js" */),
  "component---src-pages-posts-index-js": () => import("./../../../src/pages/posts/index.js" /* webpackChunkName: "component---src-pages-posts-index-js" */),
  "component---src-pages-seo-en-js": () => import("./../../../src/pages/seo/en.js" /* webpackChunkName: "component---src-pages-seo-en-js" */),
  "component---src-pages-seo-index-js": () => import("./../../../src/pages/seo/index.js" /* webpackChunkName: "component---src-pages-seo-index-js" */),
  "component---src-templates-blog-page-js": () => import("./../../../src/templates/blog-page.js" /* webpackChunkName: "component---src-templates-blog-page-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-category-template-js": () => import("./../../../src/templates/category-template.js" /* webpackChunkName: "component---src-templates-category-template-js" */),
  "component---src-templates-tag-template-js": () => import("./../../../src/templates/tag-template.js" /* webpackChunkName: "component---src-templates-tag-template-js" */)
}

